import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	const currentPosition = getSavedScrollPosition(location);

	setTimeout(() => {
		window.scrollTo(...(currentPosition || [0, 0]));
	}, 0);

	return false;
};